'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.factory:DispatchFilters

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'DispatchFilters', [
    'DispatchGroups'
    'IncidentPriorities'
    'DispatchUnitStatus'
    'DispatchUnitTypes'
    'DispatchUnit'
    'UnitType'
    'Tasks',
    '$log'
    '$q',
    '$filter',
    'RestUtils',
    '$translate',
    'mundoConfiguration'
    (DispatchGroups, IncidentPriorities, DispatchUnitStatus, DispatchUnitTypes, DispatchUnit, UnitType, Tasks, $log, $q, $filter, RestUtils, $translate, mundoConfiguration) ->
      DispatchFiltersBase = {}

      @dispatchFeatures = mundoConfiguration.dispatchFeatures

      ###
      # FilterConfig is an array to configure
      # the available filters on the dispatching map
      # Get the actual options somewhere else.
      # Some get a service variable that is a rest factory
      ###
      DispatchFiltersBase.FilterConfig = [
        {
          title: "app.dispatch.filtervehicles"
          machineName: "vehicle_filters"
          open: false
          filters: [
            {
              label: 'app.dispatch.dispatchstatus'
              key: 'dispatchStatus'
              machineName: "has_dispatch_unit"
              multiple: true
              single: true
            },
            {
              label: 'app.dispatch.dispatchgroup'
              machineName: "dispatch_group_id"
              service: DispatchGroups
              multiple: true
              single: false
              options: []
              sort: "label, ASC"
            },
            {
              label: 'app.dispatch.unittypes'
              machineName: "dispatch_unit_type_id"
              service: UnitType
              options: []
              multiple: true
              single: false
              match: (option) ->
                return {
                  'dispatchUnitType':
                    'id': option
                }
            },
            {
              label: 'app.dispatch.unitstatuses'
              machineName: "dispatch_unit_status_id"
              service: DispatchUnitStatus
              options: []
              multiple: true
              single: false
              match: (option) ->
                return {
                  'dispatchUnitStatus':
                    'id': option
                }
            },
          ]
        },
        {
          title: "app.dispatch.filterincidents"
          machineName: "incident_filters"
          open: false
          filters: [
            {
              label: 'app.dispatch.dispatchgroup'
              machineName: "dispatchGroup"
              service: DispatchGroups
              multiple: true
              single: false
              options: []
              sort: "label, ASC"
            },
            {
              label: 'app.dispatch.priorities'
              machineName: 'incidentPriority'
              service: IncidentPriorities
              multiple: true
              single: false
              options: []
              sort:  "weight,ASC"
            },
            {
              label: 'app.dispatch.incident-status'
              machineName: 'incidentDispatchStatus'
              multiple: true
              single: false
              options: [
                {'id': 'dispatched', 'label': 'app.dispatch.incident-filter.dispatched-incidents'},
                {'id': 'not_dispatched', 'label': 'app.dispatch.incident-filter.not-dispatched-incidents'}
              ]
            }
          ]
        },
      ]

      if @dispatchFeatures? && @dispatchFeatures != 'LPA'
        DispatchFiltersBase.FilterConfig.push {
          title: "app.dispatch.filterpoiorders"
          machineName: "poi_order_filters"
          open: false
          filters: [
            {
              label: 'app.dispatch.unit-type'
              machineName: "poiDispatchUnitType"
              multiple: true
              single: false
              options: [
                {'id': 'dispatch_unit', 'label': 'dispatch unit'},
                {'id': 'unit', 'label': 'unit'}
              ]
              # sort: "label, ASC"
            },
            {
              label: 'app.dispatch.type'
              machineName: "poiOrderType"
              service: Tasks
              multiple: true
              single: false
              options: []
              sort: "createdAt, DESC"
              filters: {
                'filter[0]': 'closedAt,NULL'
              , 'filter[1]': 'taskType.code,poi_order'
              , 'filter[2]': 'lpaPoiOrder.id,NOT_NULL'
              , 'filter[3]': 'lpaPoiOrder.closedAt,NULL',
              }
              getChild: 'lpaPoiOrder'
              relabel: 'type'
              reidentify: 'type'
            },
            {
              label: 'app.hideAll'
              machineName: "poiOrderHideAll"
              multiple: false
              single: true
              options: [
                { 'id': true, 'label': 'app.hideAll' }
              ]
            }
          ]
        }

      DispatchFiltersBase.getFilters = ($scope) ->
        promises = []
        angular.forEach @FilterConfig, (filterGroup) ->
          angular.forEach filterGroup.filters, (filter) ->
            params = {}
            if filter.service
              if filter.sort
                params.sort = filter.sort
              if filter.filters
                params = angular.extend(params, filter.filters)
              
              filterPromise = RestUtils.getFullList(filter.service, params)
              filterPromise.then (results) ->
                filter.options = results
                if filter.getChild
                  filter.options = _.pluck(results, filter.getChild)
                if filter.relabel
                  filter.options = _.each(filter.options, (option) ->
                    option.label = option[filter.relabel]
                  )
                # not a fan of this but nothing better comes to mind if I want to filter but anything other than id
                if filter.reidentify
                  filter.options = _.each(filter.options, (option) ->
                    option.id = option[filter.reidentify]
                )

              promises.push filterPromise

        return $q.all promises

      DispatchFiltersBase
  ]
